import { Schema, model, Document } from 'mongoose';

import { ACTIVITY_GENERATION_TYPE_REFERENCE } from './enums/dynamic-model-reference.enum';
interface homePracticeInterface extends Document {}

export const HOME_PRACTICE_OPTIONS = {
  SHARE_LIMIT: 20,
  TRACKING_ID_INPUT_LIMIT: 50,
  LINK_VALID_DAYS: 150,
  LINK_ACCESS_LIMIT: 10,
};

const homePracticeSchema = new Schema<homePracticeInterface>(
  {
    linkId: { type: String, required: true, unique: true },
    createdBy: { type: Schema.Types.ObjectId, ref: 'User', required: true },
    linkAccessedAt: [{ type: Date, default: null }],
    linkAccessLogs: [
      {
        accessedAt: { type: Date, default: null },
        browserId: { type: String, default: null },
        user: { type: Schema.Types.ObjectId, ref: 'User', default: null },
      },
    ],
    clientId: { type: Schema.Types.ObjectId, ref: 'Client', default: null },
    trackingId: {
      type: String,
      default: null,
      maxLength: HOME_PRACTICE_OPTIONS.TRACKING_ID_INPUT_LIMIT,
    },
    //  activities: [{ type: Schema.Types.ObjectId, ref: 'Activity' }],
    activities: [
      {
        modelId: {
          type: Schema.Types.ObjectId,
          required: true,
          refPath: 'activities.onModel',
        },
        onModel: {
          type: String,
          required: true,
          enum: Object.values(ACTIVITY_GENERATION_TYPE_REFERENCE),
        },
      },
    ],

    board: { type: Schema.Types.ObjectId, ref: 'Board' },
    customSelfPracticeMessage: { type: String, default: null },
    // INFO: To make link that never expires
    neverExpires: { type: Schema.Types.Boolean, default: false },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const HomePractice = model<homePracticeInterface>(
  'Home-Practice',
  homePracticeSchema
) as any;
