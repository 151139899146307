import mongoose, { Document, Schema, ObjectId } from 'mongoose';
import { LetterType } from './enums/common.enum';

export interface LettersMapping {
  letter: String;
  symbol: String;
  type: LetterType;
  visualDistractors: String[];
  defaultPhonologicalDistractors?: String[] | null;
}

export interface LanguageLetters {
  language: ObjectId;
  letters: LettersMapping[];
}

export interface MongooseLanguageLetters extends LanguageLetters, Document {}

const LanguageLettersSchema = new Schema(
  {
    language: {
      type: Schema.Types.ObjectId,
      ref: 'Language',
      index: true,
    },
    letters: [
      {
        letter: { type: String, default: null, index: true },
        symbol: { type: String, default: null, index: true },
        type: { type: String, enum: Object.values(LetterType), index: true },
        visualDistractors: { type: [String], default: null },
        defaultPhonologicalDistractors: { type: [String], default: null },
      },
    ],
  },
  { timestamps: true }
);

export const LanguageLettersMapping = mongoose.model<MongooseLanguageLetters>(
  'Language-Letters-Mapping',
  LanguageLettersSchema
) as any;
