export enum ELEMENT_TEMPLATE_VIEWS {
  TEXT_ONLY = 'TEXT_ONLY',
  IMAGE_ONLY = 'IMAGE_ONLY',
  AUDIO_ONLY = 'AUDIO_ONLY',
}

export enum LetterType {
  STANDARD = 'standard',
  CAPITAL = 'capital',
  FINAL = 'final',
}
