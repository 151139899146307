import { ALLOWED_LANGUAGES } from './activity';
import mongoose, { Document, Schema } from 'mongoose';
import { HOME_PRACTICE_OPTIONS } from './home-practice';

export enum planType {
  BASIC = 'Basic',
  PRO = 'Pro',
}
export enum selfPracticeShareOptionsEnum {
  SHARE = 'Share',
  COPY = 'Copy',
  SMS = 'SMS',
  QR = 'QR',
  EMAIL = 'EMAIL',
}

export enum organizationAddClientView {
  ID_AND_NAME = 'ClientIDAndClientName',
  ID = 'ClientID',
  NAME = 'ClientName',
}

export enum ORG_OR_ADMIN_DASHBOARD_TYPE {
  SYNC_SESSION = 1,
  SYNC_ACTIVITY = 2,
  ASYNC_SESSION = 3,
  ASYNC_ACTIVITY = 4,
}

export const ORGANIZATION_ENUM = {
  planType: Object.values(planType),
  defaultLanguageCode: Object.values(ALLOWED_LANGUAGES),
};

export interface Organization {
  name: string;
}

export const ORGANIZATION_PRINTS_LIMIT = {
  orgPrintLimit: 100,
  orgPrintsLeftThisMonth: 100,
};

export const ORGANIZATION_SELF_PRACTICE_LIMIT = {
  organizationSelfPracticeLimit: 50,
  organizationSelfPracticeMonthlyLimit: 50,
};

export const PRIMARY_ORGANIZATION = [
  '6375eae5900b319db072a92f',
  '631ed7d0e8c24fce83812d3c',
];

export const SESSION_WILL_EXPIRE_WARNING_TIME = 20;

export interface MongooseOrganization extends Organization, Document {}

const providertype = {
  prod: { type: String },
  ci: { type: String },
};

const OrganizationSchema = new Schema(
  {
    name: { type: String, index: true },
    title: { type: Object },
    contactEmail: { type: String },
    logoPath: { type: String },
    purchase: { type: Schema.Types.ObjectId, ref: 'Purchase' },
    plan: { type: Schema.Types.ObjectId, ref: 'Purchase' },
    planType: { type: String, enum: ORGANIZATION_ENUM.planType },
    dataCollectionEnabled: { type: Boolean },
    active: { type: Boolean },
    roles: [
      {
        id: { type: Schema.Types.ObjectId, ref: 'Role' },
        expirationDate: { type: Date },
      },
    ],
    adminUsersIds: [{ type: Schema.Types.ObjectId, ref: 'User' }],
    defaultLanguageCode: {
      type: String,
      enum: ORGANIZATION_ENUM.defaultLanguageCode,
    },
    comments: { type: String },
    purchaseComments: { type: String },
    externalPaymentPlan: { type: Boolean, default: false },
    isPaying: { type: Boolean, default: false },
    totalUserLicenses: { type: Number, default: 10 },
    totalClientLicenses: { type: Number, default: 200 },

    isExternalOrganization: { type: Boolean, default: false },
    preferences: {
      selfPracticeShareOptions: [
        {
          type: String,
          enum: Object.values(selfPracticeShareOptionsEnum),
          default: null,
        },
      ],
      selfPracticeSmsApiKey: {
        type: String,
        default: null,
        select: false,

        /* Below key is for restify to remove key explicitly
        even if someone ask for this specific key
        */
        access: 'private',
      },
      allowsTrackingId: { type: Boolean, default: true },
      printsLimit: {
        type: Number,
        default: ORGANIZATION_PRINTS_LIMIT.orgPrintLimit,
      },
      printsLeftThisMonth: {
        type: Number,
        default: ORGANIZATION_PRINTS_LIMIT.orgPrintsLeftThisMonth,
      },
      phoneticsListOrder: {
        type: Object,
      },
      autoGeneratedClientID: { type: Boolean, default: null },
      hasClientName: {
        type: Boolean,
        default: null,
      },
      removeOpenFields: {
        type: Boolean,
        default: false,
      },
      hasCustomTermsOfUse: {
        type: Boolean,
        default: false,
      },
      hasCustomClientTermsOfUse: {
        type: Boolean,
        default: false,
      },
      enableOrganizationReports: {
        type: Boolean,
        default: false,
      },
      showTimeRelatedDataInReports: {
        type: Boolean,
        default: false,
      },
      showTimeRelatedDataInSessionReports: {
        type: Boolean,
        default: false,
      },
      clientNameRegex: {
        type: String,
      },
      clientNameRegexMsg: {
        type: Object,
      },
      allowCustomMessageToSelfPractice: { type: Boolean, default: true },
    },
    selfPractice: {
      selfPracticeShareLimit: {
        type: Number,
        default: ORGANIZATION_SELF_PRACTICE_LIMIT.organizationSelfPracticeLimit,
      },
      shareLeftThisMonth: {
        type: Number,
        default:
          ORGANIZATION_SELF_PRACTICE_LIMIT.organizationSelfPracticeMonthlyLimit,
      },
    },
    provider: {
      type: Object,
      default: null,
      name: { type: String, unique: true },
      loginUrl: providertype,
      logoutUrl: providertype,
      cert: providertype,
      select: false,
    },
    hasLogo: {
      type: Boolean,
      default: false,
    },
    autoLogout: { type: Boolean, default: false },
    autoLogoutOnBrowserClose: { type: Boolean, default: false },
    autoLogoutTimeout: { type: Number, default: 0 },
    autoLogoutUrl: { type: String, default: null },
    sessionMaxTime: { type: Number, default: 0 },
    selfPracticeLinkAccessLimit: {
      type: Number,
      default: HOME_PRACTICE_OPTIONS.LINK_ACCESS_LIMIT,
    },
    websiteAccess: {
      type: [Schema.Types.ObjectId],
      default: [],
    },
    updatedBy: { type: Schema.Types.ObjectId, ref: 'User' },
  },
  { timestamps: { createdAt: 'createdAt', updatedAt: 'updatedAt' } }
);

export const Organization = mongoose.model<MongooseOrganization>(
  'Organization',
  OrganizationSchema
) as any;
