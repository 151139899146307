import mongoose, { Document, Schema } from 'mongoose';

import { cardOptions } from './phonetics-words';
import { ACTIVITY_ENUM } from './activity';

export interface UserDefinedActivityTempInterface {
  name: string;
}

export interface MongooseUserDefinedActivityTemp
  extends UserDefinedActivityTempInterface,
    Document {}

const UserDefinedActivityTempSchema = new Schema(
  {
    name: { type: String, unique: false },
    type: { type: Schema.Types.ObjectId, ref: 'Activity-Type' }, // Renamed it to type instead of activityType as we are using type for the activities collections.
    createdBy: {
      type: Schema.Types.ObjectId,
      ref: 'User',
    },
    punctuatedText: {
      type: Boolean,
      default: false,
    },
    languageCode: { type: [String], enum: ACTIVITY_ENUM.languageCode },
    ownedBy: { type: Schema.Types.ObjectId, ref: 'Organization' },
    data: { type: Array, default: [] },
    activityInfo: {
      generalInfo: {},
      flashCardInfo: {
        itemsPerSlide: { type: Number },
        mode: { type: String, enum: Object.values(cardOptions).concat([null]) },
        isOtherOption: { type: Boolean, default: false },
      },
      memoryCardInfo: {
        type: { type: String, enum: Object.values(cardOptions).concat([null]) },
        layout: { type: Object },
        isOtherOption: { type: Boolean, default: false },
        showNumber: { type: Boolean, default: false },
      },
    },
  },
  { timestamps: true }
);

export const UserDefinedActivityTemp =
  mongoose.model<MongooseUserDefinedActivityTemp>(
    'user-defined-activity-temp',
    UserDefinedActivityTempSchema
  ) as any;
