import mongoose, { Document, Schema } from 'mongoose';
import { Domain } from './domain';

export enum ActivityTypes {
  Plain = 'Plain',
  Facts = 'Facts',
  Categorization = 'Categorization',
  PhotoAssembly = 'PhotoAssembly',
  WhatsInThePicture = 'WhatsInThePicture',
  MemoryCards = 'MemoryCards',
  MemoryPairs = 'MemoryPairs',
  PicturePuzzle = 'PicturePuzzle',
  WhatIsWrong = 'WhatIsWrong',
  Zoom = 'Zoom',
  CommonGround = 'CommonGround',
  Meaning = 'Meaning',
  WHQuestions = 'WHQuestions',
  GoodStory = 'GoodStory',
  SoundOfLifePhoto = 'SoundOfLifePhoto',
  StorySequence = 'StorySequence',
  ActivityPlanningBasic = 'ActivityPlanningBasic',
  Comprehension = 'Comprehension',
  Categorize = 'Categorize',
  SpotDifferences = 'SpotDifferences',
  PointOfView = 'PointOfView',
  SocialInferencing = 'SocialInferencing',
  IdentifyingEmotions = 'IdentifyingEmotions',
  NameRecall = 'NameRecall',
  SpotDifferencesNew = 'SpotDifferencesNew',
  WhatTheConnection = 'WhatTheConnection',
  Worksheets = 'Worksheets',
  AuditoryComprehensionWord = 'AuditoryComprehensionWord',
  AuditoryComprehensionSentence = 'AuditoryComprehensionSentence',
  AuditoryComprehensionPhrase = 'AuditoryComprehensionPhrase',
  Naming = 'Naming',
  OddOneOut = 'OddOneOut',
  Sequencing = 'Sequencing',
  WhatDoesntFit = 'WhatDoesntFit',
  ActivityPoc = 'ActivityPoc',
  ActivityPlanning = 'ActivityPlanning',
  WeeklySchedule = 'WeeklySchedule',
  PictureDescription = 'PictureDescription',
  WordFromTheDefinition = 'WordFromTheDefinition',
  MemoryPairsStudio = 'MemoryPairsStudio',
  FlashCardsStudio = 'FlashCardsStudio',
  SpeechSoundPictureScenes = 'SpeechSoundPictureScenes',
  SimilarityAndDifference = 'SimilarityAndDifference',
  Judgement = 'Judgement',
  RecallDailyTasks = 'RecallDailyTasks',
  PracticeWritingWordLevel = 'PracticeWritingWordLevel',
  AbsurdPictures = 'AbsurdPictures',
  Anagram = 'Anagram',
}

export interface ActivityType {
  name: string;
  label: string;
  domain: Domain;
}

export interface MongooseActivityType extends ActivityType, Document {}

const ActivityTypeSchema = new Schema({
  name: { type: String, unique: true, index: true },
  label: { type: String, unique: false, index: true },
  new: { type: Boolean, default: false },
  domain: { type: Schema.Types.ObjectId, ref: 'Activity-Domain' },
  sourceActivityType: {
    type: Schema.Types.ObjectId,
    ref: 'Activity-Type',
    default: null,
  },
  sortOrder: { type: Number },
  isPublished: { type: Boolean, default: false },
  multipleLevel: { type: Boolean, default: false },
  hasTextToSpeechAudio: { type: Boolean, default: false },
  hiddenInLanguages: { type: [Schema.Types.ObjectId], default: [] },
});

export const ActivityType = mongoose.model<MongooseActivityType>(
  'Activity-Type',
  ActivityTypeSchema
);
