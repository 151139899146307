import Vue from 'vue';
import VueRouter from 'vue-router';
import scrollBehavior from './scroll-behavior';
import { layout, root, route } from './util';

Vue.use(VueRouter);
//
const routes = root([
  layout('/pages', 'Frontend', [
    {
      path: ':collection',
      name: 'collection',
      component: () => import(/* webpackChunkName: "layout-[request]" */ `@/pages/collections/index`),
      meta: { requiresAuth: true },
      children: [
        {
          path: '',
          component: () => import(/* webpackChunkName: "layout-[request]" */ `@/pages/collections/index-page`),
          meta: { requiresAuth: true },
        },
        {
          path: 'add',
          component: () => import(/* webpackChunkName: "layout-[request]" */ `@/pages/collections/add`),
          meta: { requiresAuth: true },
        },
        {
          path: ':id',
          component: () => import(/* webpackChunkName: "layout-[request]" */ `@/pages/collections/view`),
          meta: { requiresAuth: true },
        },
      ],
    },

    {
      path: '/text-to-speech',
      component: () => import(/* webpackChunkName: "layout-[request]" */ `@/pages/text-to-speech-test-page/index`),
      meta: { requiresAuth: true },
    },
    {
      path: '*',
      redirect: '/404',
    },
  ]),
  layout('/redirect/:token', 'blank', []),
  layout('', 'Auth', [
    {
      path: '',
      redirect: '/pages/activity',
    },
    route('login', 'login'),

    route('error', 'error'),
    route('404', '404'),

    {
      path: '*',
      redirect: '/404',
    },
  ]),
]) as any;

console.log({ routes });

export function createRouter() {
  const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior,
  });

  return router;
}
