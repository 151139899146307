import mongoose, { Document, Schema, ObjectId } from 'mongoose';
import { LetterType } from './enums/common.enum';

export enum DataKeys {
  DISTRACTORS = 'DISTRACTORS',
}

export interface DistractorsMapping {
  language: ObjectId;
  symbol: String;
  type: LetterType;
  letter: String;
  visualDistractors: String[];
  defaultPhonologicalDistractors?: String[] | null;
}

export interface GeneralData {
  distractorsMapping: DistractorsMapping;
}

export interface MongooseGeneralData extends GeneralData, Document {}

const GeneralDataSchema = new Schema(
  {
    distractorsMapping: {
      language: {
        type: Schema.Types.ObjectId,
        ref: 'Language',
        default: null,
      },
      symbol: { type: String, default: '' },
      type: { type: String, enum: Object.values(LetterType) },
      letter: { type: String, default: '' },
      visualDistractors: { type: [String], default: null },
      defaultPhonologicalDistractors: { type: [String], default: null },
    },
  },
  { timestamps: true }
);

export const GeneralData = mongoose.model<MongooseGeneralData>(
  'General-Data',
  GeneralDataSchema
) as any;
